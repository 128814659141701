/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { lazy, Suspense } from 'react';

import CmsBlock from 'Component/CmsBlock';
import ContentWrapper from 'Component/ContentWrapper';
import CategoryPage from 'Route/CategoryPage/CategoryPage.component.js';
import {
    GRID_LAYOUT,
    LIST_LAYOUT
} from 'Route/CategoryPage/CategoryPage.config';
import { isCrawler, isSSR } from 'Util/Browser';

import InsignCategoryProductList from '../../component/InsignCategoryProductList';

import 'Route/CategoryPage/CategoryPage.style';

export const CategoryFilterOverlay = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "overlays-category" */ 'Component/CategoryFilterOverlay'
));

/** @namespace Insign/ScandipwaExtCatalog/Route/InsignCategoryPage/Component */
export class InsignCategoryPageComponent extends CategoryPage {
    renderCategoryDetails() {
        return null;
    }

    renderItemsCount(isVisibleOnMobile = false) {
        return super.renderItemsCount(isVisibleOnMobile);
    }

    renderCategoryProductList() {
        const {
            filter,
            search,
            selectedSort,
            selectedFilters,
            isMatchingListFilter,
            isCurrentCategoryLoaded,
            isMatchingInfoFilter,
            urlExtcatalogObject
        } = this.props;

        const { activeLayoutType } = this.state;

        if (!this.displayProducts()) {
            return null;
        }

        return (
            <div
              block="CategoryPage"
              elem="ProductListWrapper"
              mods={ { isPrerendered: isSSR() || isCrawler() } }
            >
                { this.renderItemsCount(true) }
                <InsignCategoryProductList
                  filter={ filter }
                  search={ search }
                  sort={ selectedSort }
                  selectedFilters={ selectedFilters }
                  isCurrentCategoryLoaded={ isCurrentCategoryLoaded }
                  isMatchingListFilter={ isMatchingListFilter }
                  isMatchingInfoFilter={ isMatchingInfoFilter }
                  layout={ activeLayoutType || GRID_LAYOUT }
                  canonicalObject={ urlExtcatalogObject }
                />
            </div>
        );
    }

    renderExtcatalogCmsBlcok() {
        const {
            urlExtcatalogObject,
            isCountryPage,
            isBrandPage,
            entity_id,
        } = this.props;

        if (isCountryPage || isBrandPage) {
            return (
                <div
                  block="CategoryPage"
                  elem="CMS"
                >
                    <CmsBlock identifier={ isBrandPage ? `shortdescription_brand_${entity_id}` : `shortdescription_country_${entity_id}` } />
                </div>
            );
        }

        if (!urlExtcatalogObject) {
            return null;
        }

        const { cms_block } = urlExtcatalogObject;

        if (!cms_block) {
            return null;
        }

        return (
            <div
              block="CategoryPage"
              elem="CMS"
            >
                <CmsBlock identifier={ cms_block } />
            </div>
        );
    }

    renderPageTitle() {
        const { urlExtcatalogObject } = this.props;

        if (!urlExtcatalogObject.title) {
            return null;
        }

        return (
            <div block="CategoryPage" elem="TitleWrapper">
                <h2>{ decodeURIComponent(urlExtcatalogObject.title) }</h2>
            </div>
        );
    }

    renderpageInfo() {
        const hideProducts = !this.displayProducts();
        const {
            isCigarFinderPage
        } = this.props;

        if (isCigarFinderPage) {
            return null;
        }

        return (
            <ContentWrapper
              wrapperMix={ {
                  block: 'CategoryPage',
                  elem: 'InfoWrapper Wrapper',
                  mods: { hideProducts }
              } }
              label="Category page Infos"
            >
                <div block="CategoryPage" elem="InfoInnerWrapper">
                    { this.renderPageTitle() }
                    { this.renderExtcatalogCmsBlcok() }
                </div>
            </ContentWrapper>
        );
    }

    renderFilterOverlay() {
        const {
            filters,
            selectedFilters,
            isMatchingInfoFilter,
            isSearchPage,
            isCigarFinderVisible,
            isCigarFinderAvailable,
            setCigarFinderVisible,
            isBrandPage,
            isCountryPage,
            noFilters
        } = this.props;

        const { category: { is_anchor } } = this.props;

        if (noFilters) {
            return null;
        }

        if (!this.displayProducts()) {
            return null;
        }

        if (isCigarFinderAvailable === null) {
            return this.renderFilterPlaceholder();
        }

        return (
            <Suspense fallback={ this.renderFilterPlaceholder() }>
                <CategoryFilterOverlay
                  availableFilters={ filters }
                  customFiltersValues={ selectedFilters }
                  isMatchingInfoFilter={ isMatchingInfoFilter }
                  isCategoryAnchor={ !!is_anchor }
                  isSearchPage={ isSearchPage }
                  renderPlaceholder={ this.renderPlaceholder }
                  isCigarFinderAvailable={ isCigarFinderAvailable }
                  isCigarFinderVisible={ isCigarFinderVisible }
                  setCigarFinderVisible={ setCigarFinderVisible }
                  isBrandPage={ isBrandPage }
                  isCountryPage={ isCountryPage }
                />
            </Suspense>
        );
    }

    render() {
        const hideProducts = !this.displayProducts();
        const { totalItems } = this.props;

        return (
            <main block="CategoryPage" mods={ { noResults: totalItems === 0 } }>
                { this.renderpageInfo() }
                <ContentWrapper
                  wrapperMix={ {
                      block: 'CategoryPage',
                      elem: 'Wrapper',
                      mods: { hideProducts }
                  } }
                  label="Category page"
                >
                    { this.renderContent() }
                </ContentWrapper>
            </main>
        );
    }
}

export default InsignCategoryPageComponent;
