import InsignCategoryPageComponent from '../route/InsignCategoryPage';

const renderContent = (args, callback, instance) => {
    const { type, props } = instance.props;
    const {
        history,
        location,
        match,
        canonical_url,
        entity_id,
    } = props;

    console.log(props);

    switch (type) {
    case 'INSIGN_EXT_CATALOG':
        return (
                <InsignCategoryPageComponent
                  history={ history }
                  location={ location }
                  match={ match }
                  canonical_url={ canonical_url }
                  entity_id={ entity_id }
                />
        );
    default:
        return callback.apply(instance, args);
    }
};

export default {
    'Route/UrlRewrites/Component': {
        'member-function': {
            renderContent
        }
    }
};
